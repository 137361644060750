import { Container } from "shared/components/Container";
import * as S from "./styled";
import styled from "styled-components";
import Device from "./../../../../device";

const TriangleSvg = () => {
  return (
    <svg
      width="320"
      height="960"
      viewBox="0 0 320 960"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M320 640V0L0 320L320 640Z"
        fill="url(#paint0_linear_10_349)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10_349"
          x1="480"
          y1="480"
          x2="160"
          y2="160"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3282FF" />
          <stop offset="1" stop-color="#3282FF" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const TriangleWrap = styled.div`
  display: none;

  @media ${Device.laptop} {
    display: block;
  }
`;

export const Triangle2 = () => {
  return (
    <TriangleWrap
      style={{ position: "absolute", right: 0, top: 2100, zIndex: -200 }}
    >
      <TriangleSvg />
    </TriangleWrap>
  );
};
export const Triangle1 = () => {
  return (
    <TriangleWrap
      style={{ position: "absolute", right: 0, top: 450, zIndex: -200 }}
    >
      <TriangleSvg />
    </TriangleWrap>
  );
};
export const Triangle3 = () => {
  return (
    <TriangleWrap
      style={{ position: "absolute", left: 0, top: 1250, zIndex: -200 }}
    >
      <svg width="317" height="640" viewBox="0 0 317 640" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" d="M-2.99999 640L-2.99994 0L317 320L-2.99999 640Z" fill="url(#paint0_linear_81_220)"/>
<defs>
<linearGradient id="paint0_linear_81_220" x1="-163" y1="480" x2="157" y2="160" gradientUnits="userSpaceOnUse">
<stop stop-color="#3282FF"/>
<stop offset="1" stop-color="#3282FF" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

    </TriangleWrap>
  );
};

const Technology = () => {
  return (
    <S.Section id="technology">
      <Container>
        <S.Title>Technology highlights</S.Title>
        <S.TGrid>
          <S.TText>
            <S.SubTitle>Unlimited exchange accounts in one place</S.SubTitle>
            <p>
              Unlock the full potential of your crypto portfolio with{" "}
              <b>Multik</b>.
            </p>

            <p>
              Connect an unlimited number of accounts from 10+ top tier
              cryptocurrency exchanges and view a consolidated summary of all
              your assets in one place.
            </p>

            <p>
              With just a few clicks, effortlessly transfer funds between
              accounts, ensuring optimal management of your digital assets.
            </p>

            <p>
              Experience unparalleled convenience and control over your crypto
              holdings with our unified asset management solution.
            </p>
          </S.TText>
          <S.TPic style={{ width: "100%" }}>
            <S.Pic>
              <img src="/i/technology-overview.png" alt="" />
            </S.Pic>
            <div
              style={{
                position: "absolute",
                top: "-10%",
                width: "56.5%",
                height: "125%",
                background: "var(--foreground)",
                borderRadius: "5px",
                zIndex: -100,
              }}
            ></div>
          </S.TPic>
        </S.TGrid>
        <S.TGridSwapped>
          <S.TPic>
            <S.Pic style={{ textAlign: "center" }}>
              <img
                style={{ width: "70%" }}
                src="/i/technology-enclave.png"
                alt=""
              />
            </S.Pic>
          </S.TPic>
          <S.TText>
            <S.SubTitle>Secure Intel® SGX Enclave technology</S.SubTitle>
            <p>
              The <b>Multik</b> platform leverages Intel SGX technology for
              top-tier data security.
            </p>
            <p>
              SGX enclaves are isolated memory areas, protected from
              unauthorized access, even by the OS. Not even Multik developers
              can access the data within.
            </p>
            <p>
              All withdrawal requests are securely signed within the Enclave,
              ensuring only authorized actions are executed, eliminating forgery
              risks.
            </p>
            <p>
              The Provisioning App for admin connects to the Enclave via a
              secure API, ensuring safe storage and processing of confidential
              information.
            </p>
          </S.TText>
        </S.TGridSwapped>

        <S.TGrid>
          <S.TText>
            <S.SubTitle>Flexible Policy Engine</S.SubTitle>
            <p>
              <b>Multik</b> features a flexible Policy Engine that allows you to
              customize user and account limits, thresholds, and account pools,
              giving you full control over fund management.
            </p>

            <p>
              Each transaction may require a quorum of votes from other
              workspace users, with customizable voting thresholds, adding an
              extra layer of protection. Transfers are only possible to
              whitelisted addresses, ensuring secure transactions by allowing
              only pre-approved destinations.
            </p>

            <p>
              These flexible transfer settings create a strong second layer of
              security on <b>Multik</b> platform.
            </p>
          </S.TText>
          <S.TPic>
            <S.Pic>
              <div
                style={{
                  position: "absolute",
                  top: "2%",
                  width: "54%",
                  height: "100%",
                  background: "var(--foreground)",
                  borderRadius: "5px",
                  zIndex: -100,
                }}
              ></div>
              <img src="/i/technology-limits.png" alt="" />
            </S.Pic>
          </S.TPic>
        </S.TGrid>
        <S.TGridSwapped style={{ alignItems: "start" }}>
          <S.TPic style={{ width: "100%" }}>
            <S.Pic>
              <div
                style={{
                  position: "absolute",
                  top: "2%",
                  width: "36%",
                  height: "98%",
                  background: "var(--foreground)",
                  borderRadius: "5px",
                  zIndex: -100,
                }}
              ></div>
              <img
                style={{ width: "70%" }}
                src="/i/technology-analytics.png"
                alt=""
              />
            </S.Pic>
          </S.TPic>
          <S.TText>
            <S.SubTitle>Analytics and reporting</S.SubTitle>
            <p>
              The <b>Multik</b> platform offers user-friendly analytics and
              detailed reporting on transactions and trading positions.
            </p>
            <p>
              Easily export reports to keep track of your financial activities
              and make informed decisions.
            </p>
            <p>
              With <b>Multik</b>, you have all the tools you need for
              comprehensive financial oversight at your fingertips.
            </p>
          </S.TText>
        </S.TGridSwapped>
        <S.FlexNumbers>
          <S.FlexNumbersItem>
            <S.FlexNumbersItemNumber>$1B+</S.FlexNumbersItemNumber>
            <S.FlexNumbersItemText>Secured transactions volume</S.FlexNumbersItemText>
          </S.FlexNumbersItem>
          <S.FlexNumbersItem>
            <S.FlexNumbersItemNumber>20+</S.FlexNumbersItemNumber>
            <S.FlexNumbersItemText>Leading blockchains</S.FlexNumbersItemText>
          </S.FlexNumbersItem>
          <S.FlexNumbersItem>
            <S.FlexNumbersItemNumber>80+</S.FlexNumbersItemNumber>
            <S.FlexNumbersItemText>Supported tokens</S.FlexNumbersItemText>
          </S.FlexNumbersItem>
        </S.FlexNumbers>
      </Container>
      <Triangle1 />
      <Triangle2 />
      <Triangle3 />
    </S.Section>
  );
};

export default Technology;
